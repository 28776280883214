<template>
        <v-sheet v-if= "demoJSON">

         <v-sheet   class="primary">
          <v-container >
            <v-row>
              <v-col class="text-center white--text text-h2" cols="12" >
               
                <b>{{demoJSON.name}}</b>
              

              </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex justify-center mt-2 mb-2">
                  <v-btn @click="launchDemo()"
                    elevation="2"
                    
                    color="white"
                  >
                  Launch Demo
                </v-btn>
                  <v-btn @click="$vuetify.goTo('#overview')"
                    elevation="2"
                    
                    color = "secondary" 
                    class="ml-2"
                  >
                  Read More
                </v-btn>
                </v-col>

              </v-row>
              <v-row>
              <v-col >
                
                <v-img
              :src="demoJSON.titleImage"
              height="700"
              contain
              class="primary"
              
            ></v-img>
           


              
            </v-col>
          </v-row>
              
        


          </v-container>


         </v-sheet>
       <v-sheet id="overview" class="grey lighten-5">
            <v-container>
              <v-row class="mt-2" >
              <v-col cols="12"  >

                    <v-row>
                    <v-col align-self="start"   class="text-h4 primary--text" cols="12" >
                        An Overview of the Use Case
                    </v-col>
                    </v-row>
                    <v-row>
                    <v-col align-self="start"  class=" " lg="6" sm="6" xs="12"  >
                     
                      
                      <v-card elevation="0" fill-height class="overflow-auto transparent">
                        
                        <p class="overline grey--text text-lighten-3 text-left mb-1">Business Problem</p>
                        <p class="text-h5 primary--text" >
                          {{demoJSON.businessProblem.title}}
                        </p>
                        <v-card-text class=" mt-2 mb-2 black--text font-weight-light text-left title">
                          {{demoJSON.businessProblem.description}}
                        </v-card-text>

                        </v-card>
                        <p class="text-h6 primary--text text-lighten-3 text-left">Business Impact</p>
                        <v-list-item v-for="impact in demoJSON.businessProblem.businessImpact" :key="impact">
                            <v-list-item-avatar>
                              <v-icon
                                class="grey darken-3 "
                                dark
                              >
                                mdi-folder
                              </v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title class="text-left" >{{impact}}</v-list-item-title>
                            </v-list-item-content>

                          </v-list-item>
                          
                    </v-col > 
                    <v-col align-self="start"  class="" lg="6" sm="6" xs="12" >
                         <v-card elevation="0" class="transparent" >
                        
                            <p class="overline grey--text text-lighten-3 text-left mb-1">Proposed Solution</p>
                            <p class="text-h5 primary--text" >
                              {{demoJSON.proposedSolution.title}}
                            </p>
                            <v-card-text class=" mt-2 mb-2 black--text font-weight-light text-left title">
                              {{demoJSON.proposedSolution.description}}
                            </v-card-text>
    
                            </v-card>
                            <p class="text-h6 primary--text text-lighten-3 text-left">Features</p>
                            <v-list-item
                                v-for="feature in demoJSON.proposedSolution.features" :key="feature"
                              >
                                <v-list-item-avatar>
                                  <v-icon
                                    class="grey darken-3"
                                    dark
                                  >
                                    mdi-folder
                                  </v-icon>
                                </v-list-item-avatar>
    
                                <v-list-item-content>
                                  <v-list-item-title class=" text-left" > {{feature}}</v-list-item-title>
                                </v-list-item-content>
    
                              </v-list-item>
                              
                        
                    </v-col > 
                   
                    </v-row>
                    </v-col>
                    </v-row>
          </v-container>
                 
           </v-sheet>
           <v-sheet>
            <!-- <v-parallax
            src="https://img.freepik.com/free-vector/background-elegant-white-texture_23-2148438405.jpg?size=626&ext=jpg"
            
            
          > -->
            <v-container >
              <v-row >
              <v-col cols="12">
                <p class="text-h4 text-left primary--text">User Personas</p>
                      
                  
                      <v-card  v-for="persona in demoJSON.personas" elevated="0" outlined class="mb-4 transparent" :key="persona">
                        
                        <v-card-title class="text--primary text-h5 " >
                          {{persona.name}}
                        </v-card-title>
                        <v-card-text class=" black--text font-weight-light  mt-2 text-left title">
                          {{persona.description}}
                        </v-card-text>

                        </v-card>
                        

              </v-col>            
            </v-row>
            </v-container>
           
           </v-sheet>
          <!-- </v-parallax> -->
           <v-sheet  id="demo" class="grey lighten-5 pb-4">
            <v-container >
              <v-row >
              <v-col v-for="demo in demoJSON.demos" cols="12" :key="demo">
                <p class="text-h4 text-left primary--text">Demo: {{demo.name}}</p>
                      
                <v-row>
                  <v-col lg="6" md="6" sm="12">
                    <p class="text-h5 text-left primary--text">Steps in Demo</p>
                
                    <v-list-item v-for="(step,index) in demo.steps" :key="step"
                            
                    >
                      <v-list-item-avatar>
                        <v-icon
                          class="grey darken-3 "
                          dark
                        >
                          mdi-numeric-{{index+1}}-circle
                        </v-icon>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title class="text-left" >{{step.name}}</v-list-item-title>
                      </v-list-item-content>

                    </v-list-item>
   
                  </v-col>

                  <v-col lg="6" md="6" sm="12">
                    <p class="text-h5 text-left primary--text">Important Links</p>
                    <v-list-item-group>
                    <v-sheet v-for="link in demo.links" :key="link">
                      <v-list-item @click="launchLink(link)"
                            
                    >
                      <v-list-item-avatar>
                        <v-icon
                          class="grey darken-3 "
                          dark
                        >
                          mdi-link
                        </v-icon>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title class="text-left" >{{link.name}}</v-list-item-title>
                      </v-list-item-content>
                      
                    </v-list-item>
                    <v-divider></v-divider>
                  </v-sheet>
                  </v-list-item-group>


                  </v-col>


                </v-row>
                  
                        

              </v-col>            
            </v-row>
            </v-container>
          </v-sheet>

         </v-sheet>
</template>

<script>
import { mapGetters } from 'vuex'
    /* eslint-disable */ 
    export default {
    name: 'IntroductionComponent',
    data: () => ({
      demoJSON:null,
    }),
    computed:{
       ...mapGetters('generalSetting', [
      'getGeneralSetting'
    ])
    },
    mounted(){
        var self=this;
        $.ajax({
              url: self.getGeneralSetting.overview,
              contentType: "text/plain",
              dataType: 'json',
              success: function(result){
                 
                  console.log(result)
                  self.demoJSON=result
                  console.log(self.demoJSON)
                  
              },
              error: function(err){
                  
                  console.log(err);
                 
                }
              });

      } ,
      methods:{
        launchDemo(){
          for (var i=0;i< this.demoJSON.demos.length;i++){
             for (var j=0;j< this.demoJSON.demos[i].links.length;i++){
                if (this.demoJSON.demos[i].links[j].type=="demo"){
                    if (this.demoJSON.demos[i].links[j].linkType=="internal"){
                      this.$router.push({path: '/' + this.demoJSON.demos[i].links[j].linkUrl })
                    }
                    else{
                       window.open(
                      this.demoJSON.demos[i].links[j].linkUrl, "_blank");
                      return;
                    }
              }
          }
        }
        },
         launchLink(link){
          
                if (link.type=="demo"){
                    if (link.linkType=="internal"){
                      this.$router.push({path: '/' + link.linkUrl })
                    }
                    else{
                       window.open(
                      link.linkUrl, "_blank");
                      return;
                    }
              }
          
        }
        
      }
        
        
    }



    
</script>