const navigation = {
  namespaced: true,
  state: () => ({
    leftNav:{},
    showCardPayment:false,
    userProfileVisibility:false,
    genAiChatVisibility: false,
    genAiSettingsVisibility: false,
    genAiFileUploadVisibility : false,
    genAiDocumentViewerVisibility:false,
    breadCrumb:[{
      text: 'Home',
      disabled:true,
      href:'#'
    }],
    view:null,
    showMessage:false,
    message:{text:"Tesing processing messagedgrdfgdf fesfrg rgrgr", type:'info'},
    genAiChats:[{
      "name": "Content Accelerator",
      "messages":{
        "creatorId":"",
        "creationTime":"",
        "text":""
      }
    },
    {
      "name": "New Chat",
      "messages":{
        "creatorId":"",
        "creationTime":"",
        "text":""
      }
    }
    
    
    ]
    
    
  }),
  mutations: { 
    
    
    
  },
  actions: { 
    updateView({state}, pView){
      state.view = pView
    },
    updateBreadCrumb({state}, pBreadCrumb){
      state.breadCrumb = pBreadCrumb
    },
    updateLeftNav ({ state },leftNavList) {
     state.leftNav=leftNavList
    },
    setShowCardPayment ({state},pShowCardPayment){
      state.showCardPayment = pShowCardPayment
    },
    setUserProfileVisibility ({state}, pUserProfileVisibility){
      state.userProfileVisibility = pUserProfileVisibility
    },
    setGenAiChatVisibility ({state}, pGenAiChatVisibility){
      state.genAiChatVisibility = pGenAiChatVisibility
    },
    setGenAiSettingsVisibility ({state}, pGenAiSettingsVisibility){
      state.genAiSettingsVisibility = pGenAiSettingsVisibility
    },
    setGenAiFileUploadVisibility ({state}, pGenAiFileUploadVisibility){
      state.genAiFileUploadVisibility = pGenAiFileUploadVisibility
    },
    setGenAiDocumentViewerVisibility ({state}, pGenAiDocumentViewerVisibility){
      state.genAiDocumentViewerVisibility = pGenAiDocumentViewerVisibility
    },
    setShowMessage ({state}, pShowMessage){
      state.showMessage = pShowMessage
    },
    setMessage ({state}, pMessage){
      state.message = pMessage
    }
  },
  getters: { 
    getView: (state) =>{
      return state.view
    },
    getBreadCrumb: (state) => {
      return state.breadCrumb
    },
    leftNavList: (state)=>{
      return state.leftNav
    },
    getShowCardPayment :(state)=> {
      return state.showCardPayment
    },
    showUserProfile : (state) =>{
      return state.userProfileVisibility
    },
    getGenAiChats :(state =>{
      return state.genAiChats
    }),
    getGenAiChatVisibility : (state) =>{
      return state.genAiChatVisibility
    },
    getGenAiSettingsVisibility : (state) =>{
      return state.genAiSettingsVisibility
    },
    getGenAiFileUploadVisibility : (state) =>{
      return state.genAiFileUploadVisibility
    },
    getGenAiDocumentViewerVisibility : (state) =>{
      return state.genAiDocumentViewerVisibility
    },
    getShowMessage: (state) =>{
      return state.showMessage
    },
    getMessage: (state) =>{
      return state.message
    },
  }
}

export default navigation;