const user = {
  namespaced: true,
  state: () => ({
      adminApiUrl: "https://mpmltr0uy0.execute-api.us-east-1.amazonaws.com/default/gen-ai-admin",

      users:[],
      tenants:[]

    
  }),
  mutations: { 
    
    
    
  },
  actions: { 
    setUsers ({ state },pUsers) {
     state.users=pUsers
    },
    setTenants ({ state },pTenants) {
      state.tenants=pTenants
     }
  },
  getters: { 
    getAdminAPIUrl: (state)=>{
      return state.adminApiUrl
    },
    getUsers:(state)=>{
      return state.users
    },
    getTenants :(state) =>{
      return state.tenants
    }
  }
}

export default user;