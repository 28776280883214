<template>

    <Hub  />

</template>

<script>
// @ is an alias to /src

import Hub from '@/components/Hub.vue'
export default {
  name: 'HubView',
  components: {
    Hub
  }
}
</script>
<style >
.bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    /*background: url( 'https://cdn.pixabay.com/photo/2016/03/26/13/09/organic-1280537_1280.jpg') no-repeat center center;*/
    background-size: cover;
    /*background-color: red;*/
    transform: scale(1.1);
  }
</style>
