import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import {Amplify} from 'aws-amplify';
import awsconfig from '@/aws-exports'; 
// import {API} from 'aws-amplify'
Amplify.configure(awsconfig);


Vue.use(Vuetify);

 
 await getSettings();
 async function getSettings(){
     
// const params = new URLSearchParams(window.location.search)
// var id="default"

// if (params.has("id")){
//   id=params.get("id")
// }

window.v =new Vuetify(        {
  // theme: defaultUserSettings.theme,
});
return true


//  await API.get("genAIUser","/tenants/"+ id).then(result=> {

//   console.log(result)
//           var  defaultUserSettings= {
//                 "awsData": result.awsData,
//                 "embed": result.embed,
//                 "theme": result.theme
                    
//                 }
                
//                 window.defaultUserSettings = defaultUserSettings
//                 window.generalSetting = result.generalSetting
//                 window.analyticsProducts = result.analyticsProducts
//                 window.customerProducts = result.customerProducts
//                 window.users = result.users
//                 window.subscriptionPlans = result.subscriptionPlans
            
//                 // defaultUserSettings.theme.dark =true
//               window.userSettings=defaultUserSettings
//               window.v =new Vuetify(        {
//                     theme: defaultUserSettings.theme,
//                   });
//               return true

//  })
//  .catch((error) => {

//   console.log(error.response);
//   if (error.response.data){
//     if (error.response.data.errorCode=='100'){
//       var theme=  {
//         "themes": {
//         "light": {
//             "primary": "#008080",
//             "secondary": "white"
//         },
//         "dark": {
//             "primary": "#f2bb35",
//             "secondary": "#a1d7ac"
//             }
//         },
//         "dark": false
//         }
//         window.v =new Vuetify(        {
//           theme: theme,
//         });
//         window.setupNeeded = true
//         return true

//     }
//   }

// });

   
  // return $.ajax({
  //     url: configApi  + "?action=getTenantDetails&tenantId=" + id ,
  //     contentType: "text/plain",
  //     dataType: 'json',
  //     success: function(result){
  //       console.log(result)
  //         var  defaultUserSettings= {
  //               "awsData": result.awsData,
  //               "embed": result.embed,
  //               "theme": result.theme
                    
  //               }
                
  //               window.defaultUserSettings = defaultUserSettings
  //               window.generalSetting = result.generalSetting
  //               window.analyticsProducts = result.analyticsProducts
  //               window.customerProducts = result.customerProducts
  //               window.users = result.users
  //               window.subscriptionPlans = result.subscriptionPlans
            
              
  //             window.userSettings=defaultUserSettings
  //             window.v =new Vuetify(        {
  //                   theme: defaultUserSettings.theme,
  //                 });
  //             return true
                  
              
  //     },
  //     error: function(err){
  //         console.log('Error on making API gateway call to retrieve dashboard embed url.');
  //         console.log(err.responseText);
  //         return "error"
          
  //       }
  //     });
   
   
   
 }
 

export default window.v

