/* eslint-disable */ 
import { embedDashboard,embedSession } from 'amazon-quicksight-embedding-sdk';
const quickSight = {
  namespaced: true,
  state: () => ({
    clientSessionId:null,
    dashboardEmbedUrl : null, //for user based dashboard embedding
    dashboard:null,
    
    //for session embedding
    sessionEmbedUrl: null, 
    session:null,
    
    //for session dashboard embedding
    sessionDashboardEmbedUrl: null, 
    sessionDashboard:null,
    
    
    qEmbedUrl: null,
    anonymousClientSessionId:null,
    // for anonymous dashboard embedding
    anonymousDashboardEmbedUrl:null, 
    anonymousDashboard:null,
    
    
    params : {
          //url: embedUrl,
          dashboardId:null,
          container: null,
          width:"100%",
          height:"100%",
          undoRedoDisabled: true, 
          resetDisabled: false,
          //sheetId: "8a2940df-2583-4fb9-9859-a9faebd2fe12",
          sheetId: null, //sheet 2
          //ae76033f-8cc6-4d1a-8325-c4d7bbbb5252_596de48b-80b1-4770-9d52-3229118f08c6 sheet 1
          sheetTabsDisabled: false
        }
    
    
  }),
  actions: { 
    fetchSessionDashboardEmbedUrl({commit,state}){
      var self=this
      
       $.ajax({
      url: "https://9lzhuo0wq1.execute-api.us-east-1.amazonaws.com/default/QSUserEmbedLambda" + '?mode=getUrl&urlType=session&dashboardId=non-existent-id',
      contentType: "text/plain",
      dataType: 'json',
      success: function(result){
          console.log(result);
          document.getElementById("emptysession").innerHTML=""
          var containerDiv=document.getElementById("emptysession")
          state.params.url = result.SessionEmbedUrl
          state.params.dashboardId="non-existent-id"
          state.params.sheetId="default"
          state.params.container=containerDiv
           state.sessionDashboard=embedSession(state.params)
           state.sessionDashboardEmbedUrl = result.SessionEmbedUrl.split("?")[0] + "?identityprovider=quicksight&isauthcode=true"
          var f= result.SessionEmbedUrl.split("?")[0] + "?identityprovider=quicksight&isauthcode=true"
          state.sessionDashboard.on("error", function(){
            state.sessionDashboardEmbedUrl = result.SessionEmbedUrl.split("?")[0] + "?identityprovider=quicksight&isauthcode=true"
          })
          return "success"
            
      },
      error: function(err){
          console.log('Error on making API gateway call to retrieve dashboard embed url.');
          console.log(err.responseText);
          return "error"
          
        }
      });
      
    },
    
    fetchAnonymousDashboardEmbedUrl({commit,state}){
      var self=this
      
       $.ajax({
      url: "https://e2yq8ewr60.execute-api.us-east-1.amazonaws.com/default/AnonymousLambda" + '?mode=getUrl',
      contentType: "text/plain",
      dataType: 'json',
      success: function(result){
          console.log(result);
          document.getElementById("emptydashboard").innerHTML=""
          var containerDiv=document.getElementById("emptydashboard")
          state.params.url = result.EmbedUrl
          state.params.dashboardId="non-existent-id"
          state.params.sheetId="default"
          state.params.container=containerDiv
           state.anonymousDashboard=embedDashboard(state.params)
          var f= result.EmbedUrl.split("?")[0] + "?identityprovider=quicksight&isauthcode=true"
          state.anonymousDashboard.on("error", function(){
            state.anonymousDashboardEmbedUrl = result.EmbedUrl.split("?")[0] + "?identityprovider=quicksight&isauthcode=true"
          })
          
          
          
          
          return "success"
            
      },
      error: function(err){
          console.log('Error on making API gateway call to retrieve dashboard embed url.');
          console.log(err.responseText);
          return "error"
          
        }
      });
    },
    async addToGroup({commit,state},groupName){
     var self=this
     console.log(groupName)
      
       return $.ajax({
      url: "https://9lzhuo0wq1.execute-api.us-east-1.amazonaws.com/default/QSUserEmbedLambda" + '?mode=addToGroup&groupName=' + groupName,
      contentType: "text/plain",
      dataType: 'json'
      
      });
    }
    
    
  },
  mutations: { 
    updateClientSessionId ({ state },pSessionId) {
     state.clientSessionId=pSessionId
    },
    updateDashboardEmbedUrl ({ state },pDashboardEmbedUrl) {
     state.dashboardEmbedUrl=pDashboardEmbedUrl
    },
    updateSessionEmbedUrl ({ state },pSessionEmbedUrl) {
     state.sessionEmbedUrl=pSessionEmbedUrl
    },
    updateQEmbedUrl ({ state },pQEmbedUrl) {
     state.qEmbedUrl=pQEmbedUrl
    },
    updateAnonymousDashboardEmbedUrl ({ state },pDashboardEmbedUrl) {
     state.anonymousDashboardEmbedUrl=pDashboardEmbedUrl
    },
    updateAnonymousSessionId ({ state },pSessionId) {
     state.anonymousClientSessionId=pSessionId
    }
  },
  getters: { 
    getClientSessionId: (state)=>{
      return state.clientSessionId
    },
    getDashboardEmbedUrl : (state) => {
      return state.dashboardEmbedUrl
    },
    getSessionDashboardEmbedUrl : (state) => {
      return state.sessionDashboardEmbedUrl
    },
    getSessionEmbedUrl : (state) => {
      return state.sessionEmbedUrl
    },
    getQEmbedUrl : (state) => {
      return state.qEmbedUrl
    },
    getAnonymousDashboardEmbedUrl : (state) => {
      return state.anonymousDashboardEmbedUrl
    },
    getAnonymousClientSessionId : (state) => {
      return state.anonymousClientSessionId
    }
    
  }
}

export default quickSight;