const user = {
  namespaced: true,
  state: () => ({
    loginUser:{},
    workFlow:[
      {
        id: "subscribe",
        name: 'Update Analytics Subscription for Customer',
        i: 'images/dynamo.png',
        features: [
          'Dynamo DB',
          'Dynamo DB Streams'
        ],
        status:0,
        statusMessage:"Not Started"
      },

      {
        id:"etl",
        name: 'Update Row Level Security and Dashboard Data',
        i: 'images/glue.png',
        features: [
          'AWS Lambda',
          'Glue Jobs'
          
          
          
        ],
        status:0,
        statusMessage:"Not Started"
      },
      {
        id:"cube",
        name: 'Transformed Data Ready for Analytics',
        i: 'images/glue.png',
        features: [
          'S3 Data Lake',
          'RedShift Serverless',
          'Redshift ML for predictions'
          
        ],
        status:0,
        statusMessage:"Not Started"
      },
      {
        id:"visualize",
        name: 'Set up Insights Workspace for Customer',
        i: 'images/quicksight.png',
        features: [
          
          'QuickSight'
        ],
        status:0,
        statusMessage:"Not Started"
      }
      
      ],
      users:[]
    
  }),
  mutations: { 
    
    
    
  },
  actions: { 
    updateLoginUser ({ state },loginUser) {
     state.loginUser=loginUser
    },
    updateUsers ({ state },users) {
     state.users=users
    },
    updateLoginUserSessionId({state},loginUser){
      state.loginUser.userId= loginUser.userId
    },
    updateLoginUserSubscriptionStatus({state},subscriptionPlan){
      state.loginUser.subscriptionPlan= subscriptionPlan
    },
    updateUserSubscriptionStatus({state},subscriptionPlan){
      state.users.forEach(user=>{
        if (state.loginUser.name== user.name){
          user.subscriptionPlan = subscriptionPlan
        }
      })
     
    },
    addNewGenDocumentRequest ({state}, pNewDocReq){
      
      
      
      
      
      
      state.loginUser.genAiDocRequests.forEach(request=>{
        request.isCurrent = false
      })
      pNewDocReq.isCurrent=true
      pNewDocReq.isEdit=false
      pNewDocReq.isPinned=false
      state.loginUser.genAiDocRequests.push(pNewDocReq)
    },
    setGenAiDocumentRequests({state},pDocRequests){
      state.loginUser.genAiDocumentRequests = pDocRequests
    },
    addNewGenAiChat ({state}, pNewChat){
      if (!state.loginUser.genAiChats)
        state.loginUser.genAiChats=[]
      state.loginUser.genAiChats.forEach(chat=>{
        chat.isCurrent = false
      })
      pNewChat.isCurrent=true
      pNewChat.isEdit=false
      pNewChat.isPinned=false
      state.loginUser.genAiChats.push(pNewChat)
    },
    updateWorkflow ({state}, pWorkFlowItem){
      state.workFlow.forEach(function(workFlowItem){
        if (pWorkFlowItem.id==workFlowItem.id){
          workFlowItem.status=pWorkFlowItem.status
          workFlowItem.statusMessage = pWorkFlowItem.message
        }
        
      })
        
      }
    
  },
  getters: { 
    getLoginUser: (state)=>{
      return state.loginUser
    },
    getWorkFlow:(state)=>{
      return state.workFlow
    },
    getUsers :(state) =>{
      return state.users
    }
  }
}

export default user;