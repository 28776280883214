const appSetting = {
  namespaced: true,
  state: () => ({
          customerProducts:[
          
          
          
          // {
          //   templateId:"crm",
          //   productDisplayName: "Credi cards",
          //   productDisplayDescription:"",
          //   productDisplayImage: "images/crm.png",
          //   productAccessGroup: "",
          //   subscriptionStatus: 'active'
          // },
          {
            templateId:"sales",
            productDisplayName: "Consumer loans",
            productDisplayDescription:"Know about your sales pipeline and make decisions on sales strategy",
            productDisplayImage: "images/car.jpg",
            productAccessGroup: "",
            subscriptionStatus: 'active'
          },
          {
            templateId:"marketing",
            productDisplayName: "Home loans",
            productDisplayDescription:"Know about your sales pipeline and make decisions on sales strategy",
            productDisplayImage: "images/finance.png",
            productAccessGroup: "",
            subscriptionStatus: 'active'
          },
          
          {
            templateId:"hr",
            productDisplayName: "Travel Insurance",
            productDisplayDescription:"Gain insights on the inventory available",
            productDisplayImage: "images/flight.png",
            productAccessGroup: "",
            subscriptionStatus: 'active'
          },
          // {
          //   templateId:"finance",
          //   productDisplayName: "Wealth Management",
          //   productDisplayDescription:"Know about your sales pipeline and make decisions on sales strategy",
          //   productDisplayImage: "images/finance.png",
          //   productAccessGroup: "",
          //   subscriptionStatus: 'active'
          // },
          
          // {
          //   templateId:"procurement",
          //   productDisplayName: "Procurement",
          //   productDisplayDescription:"Know about your sales pipeline and make decisions on sales strategy",
          //   productDisplayImage: "images/procurement.png",
          //   productAccessGroup: "",
          //   subscriptionStatus: 'active'
          // },
          // {
          //   templateId:"infra",
          //   productDisplayName: "Infra",
          //   productDisplayDescription:"Know about your sales pipeline and make decisions on sales strategy",
          //   productDisplayImage: "images/infra.png",
          //   productAccessGroup: "",
          //   subscriptionStatus: 'active'
          // },
          // {
          //   templateId:"transport",
          //   productDisplayName: "Transport",
          //   productDisplayDescription:"Know about your sales pipeline and make decisions on sales strategy",
          //   productDisplayImage: "images/flight.png",
          //   productAccessGroup: "",
          //   subscriptionStatus: 'active'
          // },
          {
            templateId:"analytics",
            productDisplayName: "Analytics",
            productDisplayDescription:"Know about your sales pipeline and make decisions on sales strategy",
            productDisplayImage: "images/analytics.png",
            productAccessGroup: "",
            subscriptionStatus: 'inactive'
          }
        
        
        
        ],
          
          analyticsProducts:[
          {
            templateId:"flight",
            productDisplayName: "Travel Insurance Analysis",
            productDisplayDescription:"Gain insights on the booking on all routes across the globe",
            productDisplayImage: "images/flight.png",
            productAccessGroup: "",
            dashboardId:"a0aa284c-20fc-466a-bdab-ce87dea4f7b6",
            dashboardName:"Flight Booking Analysis",
            dashboardImage: "images/flight.png"
          },
          {
            templateId:"inventory",
            productDisplayName: "Spend Analyis",
            productDisplayDescription:"Gain insights on the inventory available",
            productDisplayImage: "images/inventory.jpg",
            productAccessGroup: "",
            dashboardId:"d9fe6a54-10f3-4e2e-9e5c-a4c56bed50ec",
            dashboardName:"",
            dashboardImage: "images/inventory.jpg"
          },
          // {
          //   templateId:"salespipeline",
          //   productDisplayName: "Sales Pipeline Analysis",
          //   productDisplayDescription:"Know about your sales pipeline and make decisions on sales strategy",
          //   productDisplayImage: "images/salespipeline.png",
          //   productAccessGroup: "",
          //   dashboardId:"1f33d313-3cc9-4b66-a5f1-5c30edd849ae",
          //   dashboardName:"Sales Pipeline Analysis",
          //   dashboardImage: "images/salespipeline.png"
          // }
        
        
        
        ],

        selectedAnalyticsProduct:null,
        subscriptionPlans:null
        
    
  }),
  mutations: { 
    
    
    
  },
  actions: { 
    updateCustomerProducts ({ state },pCustomerProducts) {
     state.customerProducts=pCustomerProducts
    },
    updateAnalyticsProducts ({ state },pAnalyticsProducts) {
     state.analyticsProducts=pAnalyticsProducts
    },
    updateSubscriptionPlans ({ state },pSubscriptionPlans) {
     state.subscriptionPlans=pSubscriptionPlans
    },
    updateSubscriptionStatus({state}, pSubscription){
      for (var i =0; i< state.customerProducts.length;i++){
        if (state.customerProducts[i].templateId== pSubscription.id){
          state.customerProducts[i].subscriptionStatus = pSubscription.status
        }
      }
    },
    setSelectedAnalyticsProduct ({ state },pAnalyticsProduct) {
     state.selectedAnalyticsProduct=pAnalyticsProduct
    },
  },
  getters: { 
    getCustomerProducts: (state)=>{
      return state.customerProducts
    },
    getAnalyticsProducts: (state)=>{
      return state.analyticsProducts
    },
    getSubscriptionPlans: (state)=>{
      return state.subscriptionPlans
    },
    getSelectedAnalyticsProduct: (state)=>{
      return state.selectedAnalyticsProduct
    }
    
  }
}

export default appSetting;