import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './stores'

import {Amplify, Analytics} from 'aws-amplify';
import awsconfig from './aws-exports'; 
// to enable Analytics
Analytics.enable();

Amplify.configure(awsconfig);
// const plugin = document.createElement("script");
// plugin.setAttribute(
//   "src",
//   "plugins/qsembed.js"
// );
// plugin.async = true;
// document.head.appendChild(plugin);
Vue.config.productionTip = false
 
window.v= new Vue({
  vuetify
      ,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
