<template>
 <v-card flat  tile light >
  <v-container>
    <v-row class=" d-flex align-center justify-center">
      <v-col cols="12"  >

      </v-col>
      <v-col class="pa-6" cols="12">
      <p class="text-center" v-if="message.type=='processing'">
        <v-progress-circular class="mr-3"
          indeterminate
          color="primary"
        ></v-progress-circular>
       <span> {{message.text}}</span>
      </p> 


      </v-col>
    </v-row>
  </v-container>

 </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
  export default {
    name: 'HubComponent',
    props:['message'],

    data: () => ({

    }),
    mounted(){
      // if (this.getLoginUser.subscriptionPlan!=null){
      //   this.updateSubscriptionStatus({id:"analytics", status:"active"})
        
      // }
      // else{
      //   this.updateSubscriptionStatus({id:"analytics", status:"inactive"})
      // }
    },
    computed:{
      ...mapGetters('appSetting', [
      'getCustomerProducts'
    ]),
      ...mapGetters('generalSetting', [
      'getGeneralSetting'
    ]),
    ...mapGetters('user', [
      'getLoginUser'
    ]),
    activeProducts(){
      const result = this.getCustomerProducts.filter(isActive);

      function isActive(product) {
        return product.productStatus  == 'active';
      }
      return result
    },
    comingsoonProducts(){
      const result = this.getCustomerProducts.filter(isComingSoon);
  
      function isComingSoon(product) {
        return product.productStatus  == 'comingsoon';
      }
      return result
    }
    },
     methods:{
       ...mapActions('appSetting', ['updateSubscriptionStatus']),
    launch(product){
      if (product.templateId=='generativeai'){
        this.$router.push({name: 'GenAILanding'})
      }
      else{
        //this.showCardPaymentPopup (true)
      }
    },
    ...mapActions('navigation', ['setShowCardPayment']),
    
    subscribe(product){
      if (!product){
        console.log(product)
      }
      // if (product.templateId=='analytics'){
      //   this.$router.push({path: '/analytics-pricing'})
      // }
      // else{
      //   this.showCardPaymentPopup (true)
      // }
    },
    showCardPaymentPopup(){
         this.setShowCardPayment(true)
    }

    
  
    
  }
  }
</script>
