const cart = {
  namespaced: true,
  state: () => ({
    items:[],
    amount: 0
    
  }),
  mutations: { 
    
    
    
  },
  actions: { 
    addToCart ({ state },item) {
    state.items = []  
     state.items.push(item)
    },
    removeFromCart ({state}, pItem){
      state.items.forEach(function (item, index) {
        if (item.id == pItem.id)
        {
          state.items.splice(index,1)
        }
      });
    },
    updateAmount ({state}, pAmount){
      state.amount=pAmount
    }
  },
  getters: { 
    getItems: (state)=>{
      return state.items
    },
    getAmount : (state)=>{
      return state.amount
    }
  }
}

export default cart;