import Vue from 'vue'
import Vuex from 'vuex'
import navigation from './navigation'
import appSetting from './appSetting'
import generalSetting from './generalSetting'
import user from './user'
import cart from './cart'
import quickSight from './quickSight'
import admin from './admin'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    navigation: navigation,
    appSetting: appSetting,
    generalSetting:generalSetting,
    user: user,
    cart:cart,
    quickSight:quickSight,
    admin: admin
  }
})